
import React from 'react';

/*Main boards and role clasification*/
import ConstantsComponent from '../claims/Constants.jsx';
import Powerbi from '../components/PowerBi';


/*
1 - admin
free
*/
const Statistics = () => {
  /*Retrieve the role type from the ConstantsComponent */
  const { TYPE_ID} = ConstantsComponent();
  
  const mostrarBoardadmin = !isNaN(TYPE_ID) && parseInt(TYPE_ID, 10) === 1;
  const mostrarBoard = !isNaN(TYPE_ID) && parseInt(TYPE_ID, 10) !== 1;
  /*Determine which board to display based on the role type ID 
  
  const mostrarBoardUser = !isNaN(TYPE_ID) && parseInt(TYPE_ID, 10) === 2;
  */
  //id clasica 5165d0e2-5f51-41a6-b5d7-1cc367c17d40
  /*Conditionally render the appropriate board based on the role type */
  /*ALL 2tor 
  e5dec36d-4e02-4aeb-aa67-a3ae662ff233
  */
  return (
    <div className='container_graph'>
      {mostrarBoardadmin && <Powerbi id_u='7204c31a-347b-4c7e-84ea-e0dd0d53a1b4'/>}
      {mostrarBoard && <Powerbi id_u='7fa1fb64-3499-4fef-a951-a1111b58e18e'/>}
    </div>
  );
};

export default Statistics;
