import TabRankings from "../../Graphs/Client/TabBestRankings";
import UrlProvider from "../../../Api/UrlProvider";

const Board_ExplorerUser= ()=>{

    
    const Url=`${UrlProvider.defaults.flaskURL}/capsule_data`
    return (
        <div className="container_graph">
            <div className="TabExplorerCliente_User">
                <TabRankings  Url={Url}  />
            </div>
        </div>

    );
};
export default Board_ExplorerUser;