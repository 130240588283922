import React, { useState, useEffect, useRef } from "react";
import { IoMdDownload } from "react-icons/io";
import Select from "react-select";
import SearchIcon from "../../../utils/SearchIcon.jsx";
import { TbColumnInsertLeft } from "react-icons/tb";
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";
import UrlProvider from "../../../Api/UrlProvider.jsx";
import Popupextra from "./Popupextra.jsx";
import Popupexport from "./Popupexport.jsx";
import { PiUserCirclePlusBold } from "react-icons/pi";
import SimpleModal from '../../Simplemodal.jsx'; // Asegúrate de ajustar la ruta

const defaultColumns = [
  { value: "user", label: "Usuario", style: { textAlign: "center", color: "#FF00B1", fontWeight: "bold" } },
  { value: "courseName", label: "Curso", style: { textAlign: "left", color: "#687079", fontWeight: "400" } },
  { value: "capsuleName", label: "Cápsula", style: { textAlign: "left", color: "#687079", fontWeight: "400" } },
  { value: "score", label: "Puntaje", style: { textAlign: "left", color: "#FF00B1", fontWeight: "bold" } },
  { value: "status", label: "Estado", style: { textAlign: "left", color: "#595959", fontWeight: "700" } },
  { value: "Advancement", label: "Avance(%)", style: { textAlign: "left", color: "#FF00B1", fontWeight: "bold", display: "table-cell" } }
];

const optionalColumns = [
  { value: "duration", label: "Duración", style: { textAlign: "left", color: "#687079", fontWeight: "400" } },
  { value: "ciudad", label: "Ciudad", style: { textAlign: "left", color: "#687079", fontWeight: "400" } },
  { value: "campaña", label: "Campaña", style: { textAlign: "left", color: "#687079", fontWeight: "400" } },
  { value: "regional", label: "Regional", style: { textAlign: "left", color: "#687079", fontWeight: "400" } },
  { value: "aliado", label: "Aliado", style: { textAlign: "left", color: "#687079", fontWeight: "400" } },
  { value: "segmento", label: "Segmento", style: { textAlign: "left", color: "#687079", fontWeight: "400" } }
];

const TabRankings = ({ Url }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [userFilter, setUserFilter] = useState("");
  const [courseFilter, setCourseFilter] = useState("");
  const [capsuleFilter, setCapsuleFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [cityFilter, setCityFilter] = useState("");

  const [statusOptions, setStatusOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [capsuleOptions, setCapsuleOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);


  const [selectedColumns, setSelectedColumns] = useState(defaultColumns);
  const [originalData, setOriginalData] = useState([]);
  const [popupData, setPopupData] = useState(null);


  const [isExportPopupOpen, setExportPopupOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const urlExtraInfo = `${UrlProvider.defaults.flaskURL}/prueba`;


  const openPopup = async (item) => {
    if (item.status === 'cerrado' || item.status === "registrado") {
      setIsModalOpen(true);
      return;
    }

    try {
      const response = await fetch(`${urlExtraInfo}/${item.id}/${item.idsession}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const popupData = await response.json();
      setPopupData(popupData);
      setIsPopupOpen(true);
    } catch (error) {
      console.error("Error fetching popup data:", error);
    }
  };

  const openPopupexport = () => {
    setExportPopupOpen(true);
  };

  const closePopupexport = () => {
    setExportPopupOpen(false);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Paginación
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);
  const tableContainerRef = useRef(null);
  const rowRef = useRef(null);

  // Tamaño de la tabla
  const [tableSize, setTableSize] = useState(0);

  const refTableDiv = useRef(null);

  const displayAbbreviation = (name) => {
    if (name && name.includes(" ")) {
      const index = name.indexOf(" ");
      return name.substring(0, index) + ' ' + name.substring(index + 1, index + 2);
    }
    return name || ''; // return an empty string if name is undefined
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(Url);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const rawData = await response.json();

        const tempData = rawData.map(item => ({
          user: item["User_Name"],
          id: item["id_user"],
          idsession: item["id_course_session"],
          courseName: item["Course_Name"],
          capsuleName: item["Capsule_Name"],
          score: item["Score"],
          status: item["status"],
          duration: item["Duration"],
          ciudad: item["ciudad"],
          campaña: item["campaña"],
          regional: item["regional"],
          aliado: item["aliado"],
          segmento: item["segmento"],
          Advancement: item["Advancement"]
        }));

        setData(tempData);
        setOriginalData(tempData);

        const tempUserOptions = [...new Set(tempData.map(item => item.user))].map(user => ({ value: user, label: user }));
        setUserOptions(tempUserOptions);

        const tempCourseOptions = [...new Set(tempData.map(item => item.courseName))].map(course => ({ value: course, label: course }));
        setCourseOptions(tempCourseOptions);

        const tempCapsuleOptions = [...new Set(tempData.map(item => item.capsuleName))].map(capsule => ({ value: capsule, label: capsule }));
        setCapsuleOptions(tempCapsuleOptions);

        const tempStatusOptions = [...new Set(tempData.map(item => item.status))].map(status => ({ value: status, label: status }));
        setStatusOptions(tempStatusOptions);

        const tempCityOptions = [...new Set(tempData.map(item => item.ciudad))].map(ciudad => ({ value: ciudad, label: ciudad }));
        setCityOptions(tempCityOptions);

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // Ensure loading state is updated
      }
    };

    fetchData();
  }, [Url]);


  const updateFilterOptions = (filtered) => {
    const tempUserOptions = [...new Set(filtered.map(item => item.user))].map(user => ({ value: user, label: user }));
    setUserOptions(tempUserOptions);

    const tempCourseOptions = [...new Set(filtered.map(item => item.courseName))].map(course => ({ value: course, label: course }));
    setCourseOptions(tempCourseOptions);

    const tempCapsuleOptions = [...new Set(filtered.map(item => item.capsuleName))].map(capsule => ({ value: capsule, label: capsule }));
    setCapsuleOptions(tempCapsuleOptions);

    const tempStatusOptions = [...new Set(filtered.map(item => item.status))].map(status => ({ value: status, label: status }));
    setStatusOptions(tempStatusOptions);

    const tempCityOptions = [...new Set(filtered.map(item => item.ciudad))].map(ciudad => ({ value: ciudad, label: ciudad }));
    setCityOptions(tempCityOptions);
  };

  useEffect(() => {
    let filtered = originalData;

    if (userFilter) {
      filtered = filtered.filter(item => item.user === userFilter);
    }
    if (courseFilter) {
      filtered = filtered.filter(item => item.courseName === courseFilter);
    }
    if (capsuleFilter) {
      filtered = filtered.filter(item => item.capsuleName === capsuleFilter);
    }
    if (statusFilter) {
      filtered = filtered.filter(item => item.status === statusFilter);
    }
    if (cityFilter) {
      filtered = filtered.filter(item => item.ciudad === cityFilter);
    }

    setFilteredData(filtered);
    updateFilterOptions(filtered);
  }, [userFilter, courseFilter, capsuleFilter, statusFilter, cityFilter, originalData]);


  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (rowRef.current) {
        const rowHeight = rowRef.current.clientHeight;
        const containerHeight = entries[0].contentRect.height;
        const visibleRows = Math.floor(containerHeight / rowHeight);
        setItemsPerPage(visibleRows);
      }
    });

    if (tableContainerRef.current) {
      resizeObserver.observe(tableContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  // Responsive
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        requestAnimationFrame(() => {
          let containerHeight = entry.contentRect.height;
          let containerWidth = entry.contentRect.width;
          let rowsPerPage = Math.min(Math.floor(containerHeight / 100), 4); // Altura máxima de cada fila es 90px, máximo 4 filas
          if (containerWidth >= containerHeight) {
            rowsPerPage = Math.min(Math.floor(containerHeight / 100), 4); // Máximo 5 filas para pantallas anchas o cuadradas
          }

          else {
            rowsPerPage = Math.min(Math.floor(containerHeight / 100), 4); // Máximo 8 filas para pantallas más altas que anchas
          }
          if (window.innerHeight <= 390) {
            rowsPerPage = 3;
          } else if (window.innerHeight <= 500) {
            rowsPerPage = 3;
          }

          setItemsPerPage(rowsPerPage);
          setTableSize(containerHeight);
        });
      }
    });

    resizeObserver.observe(refTableDiv.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);


  const toggleColumn = (column) => {
    setSelectedColumns((prevColumns) => {
      const isSelected = prevColumns.some(col => col.value === column.value);
      if (isSelected) {
        return prevColumns.filter(col => col.value !== column.value);
      } else {
        return [...prevColumns, column];
      }
    });
  };


  // Función para cambiar de página
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= Math.ceil(filteredData.length / itemsPerPage)) {
      setCurrentPage(newPage);
    }
  };

  // Datos para la página actual
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = filteredData.slice(startIndex, startIndex + itemsPerPage);


  return (
    <div ref={refTableDiv} className="TabHistoriMensajesU">
      <div className="titulo">
        <h2>Mejores desempeños</h2>
      </div>

      <div className="container_graph">
        <div className="filtro">
          <div className='firstc'>
            <div className='container-inputc'>
              <txt2>Usuario</txt2>
              <Select
                classNamePrefix="my-select"
                className="select-containerC"
                value={userOptions.find(option => option.value === userFilter)}
                onChange={option => setUserFilter(option ? option.value : '')}
                options={userOptions}
                placeholder={"Buscar usuario"}
                isClearable
                isSearchable
                components={{
                  DropdownIndicator: () => (
                    <div style={{ display: 'flex', alignItems: 'center', marginInline: "0.5vw", width: '1vw', maxWidth: "10px", height: 'auto' }}>
                      <SearchIcon />
                    </div>
                  ),
                }}
                styles={{
                  clearIndicator: provided => ({
                    ...provided,
                    color: 'gray',
                    width: '50%',
                    height: 'auto'
                  }),
                  singleValue: provided => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '65%',
                    width: '100%'
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '60%',
                    width: '100%',
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? '#FFE6F7' : state.isSelected ? '#FFB0E7' : null,
                    color: state.isSelected ? '#FF00B1' : 'grey',
                    cursor: 'pointer',
                    fontSize: '80%'
                  }),
                  input: (provided) => ({
                    ...provided,
                    fontSize: '60%',
                    cursor: 'pointer'
                  }),
                  menu: provided => ({
                    ...provided,
                    zIndex: 4,
                  })
                }}
              />
            </div>
            <div className='container-inputc'>
              <txt2>Curso</txt2>
              <Select
                classNamePrefix="my-select"
                className="select-containerC"
                value={courseOptions.find(option => option.value === courseFilter) || null}
                onChange={option => setCourseFilter(option ? option.value : '')}
                options={courseOptions}
                placeholder={"Buscar curso"}
                isClearable
                isSearchable
                components={{
                  DropdownIndicator: () => (
                    <div style={{ display: 'flex', alignItems: 'center', marginInline: "0.5vw", width: '1vw', maxWidth: "10px", height: 'auto' }}>
                      <SearchIcon />
                    </div>
                  ),
                }}
                styles={{
                  clearIndicator: provided => ({
                    ...provided,
                    color: 'gray',
                    width: '50%',
                    height: 'auto'
                  }),
                  singleValue: provided => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '65%',
                    width: '100%'
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '60%',
                    width: '100%',

                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? '#FFE6F7' : state.isSelected ? '#FFB0E7' : null,
                    color: state.isSelected ? '#FF00B1' : 'grey',
                    cursor: 'pointer',
                    fontSize: '80%'
                  }),
                  input: (provided) => ({
                    ...provided,
                    fontSize: '60%',
                    cursor: 'pointer'

                  }),
                  menu: provided => ({
                    ...provided,
                    zIndex: 4,
                  })
                }}
              />
            </div>
            <div className='container-inputc'>
              <txt2>Cápsula</txt2>
              <Select
                classNamePrefix="my-select"
                className="select-containerC"
                value={capsuleOptions.find(option => option.value === capsuleFilter) || null}
                onChange={option => setCapsuleFilter(option ? option.value : '')}
                options={capsuleOptions}
                placeholder={"Buscar cápsula"}
                isClearable
                isSearchable
                components={{
                  DropdownIndicator: () => (
                    <div style={{ display: 'flex', alignItems: 'center', marginInline: "0.5vw", width: '1vw', maxWidth: "10px", height: 'auto' }}>
                      <SearchIcon />
                    </div>
                  ),
                }}
                styles={{
                  clearIndicator: provided => ({
                    ...provided,
                    color: 'gray',
                    width: '50%',
                    height: 'auto'
                  }),
                  singleValue: provided => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '65%',
                    width: '100%'
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '60%',
                    width: '100%',

                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? '#FFE6F7' : state.isSelected ? '#FFB0E7' : null,
                    color: state.isSelected ? '#FF00B1' : 'grey',
                    cursor: 'pointer',
                    fontSize: '80%'
                  }),
                  input: (provided) => ({
                    ...provided,
                    fontSize: '60%',
                    cursor: 'pointer'

                  }),
                  menu: provided => ({
                    ...provided,
                    zIndex: 4,
                  })
                }}
              />
            </div>

            <div className='container-inputc'>
              <txt2>Ciudad</txt2>
              <Select
                classNamePrefix="my-select"
                className="select-containerC"
                value={cityOptions.find(option => option.value === cityFilter) || null}
                onChange={option => setCityFilter(option ? option.value : '')}
                options={cityOptions}
                placeholder={"Buscar ciudad"}
                isClearable
                isSearchable
                components={{
                  DropdownIndicator: () => (
                    <div style={{ display: 'flex', alignItems: 'center', marginInline: "0.5vw", width: '1vw', maxWidth: "10px", height: 'auto' }}>
                      <SearchIcon />
                    </div>
                  ),
                }}
                styles={{
                  clearIndicator: provided => ({
                    ...provided,
                    color: 'gray',
                    width: '50%',
                    height: 'auto'
                  }),
                  singleValue: provided => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '65%',
                    width: '100%'
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '60%',
                    width: '100%',

                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? '#FFE6F7' : state.isSelected ? '#FFB0E7' : null,
                    color: state.isSelected ? '#FF00B1' : 'grey',
                    cursor: 'pointer',
                    fontSize: '80%'
                  }),
                  input: (provided) => ({
                    ...provided,
                    fontSize: '60%',
                    cursor: 'pointer'

                  }),
                  menu: provided => ({
                    ...provided,
                    zIndex: 4,
                  })
                }}
              />
            </div>


            <div className='container-inputc'>
              <txt2>Estado</txt2>
              <Select
                classNamePrefix="my-select"
                className="select-containerC"
                value={statusOptions.find(option => option.value === statusFilter) || null}
                onChange={option => setStatusFilter(option ? option.value : '')}
                options={statusOptions}
                placeholder={"Buscar estado"}
                isClearable
                isSearchable
                components={{
                  DropdownIndicator: () => (
                    <div style={{ display: 'flex', alignItems: 'center', marginInline: "0.5vw", width: '1vw', maxWidth: "10px", height: 'auto' }}>
                      <SearchIcon />
                    </div>
                  ),
                }}
                styles={{
                  clearIndicator: provided => ({
                    ...provided,
                    color: 'gray',
                    width: '50%',
                    height: 'auto'
                  }),
                  singleValue: provided => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '65%',
                    width: '100%'
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '60%',
                    width: '100%',

                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? '#FFE6F7' : state.isSelected ? '#FFB0E7' : null,
                    color: state.isSelected ? '#FF00B1' : 'grey',
                    cursor: 'pointer',
                    fontSize: '80%'
                  }),
                  input: (provided) => ({
                    ...provided,
                    fontSize: '60%',
                    cursor: 'pointer'

                  }),
                  menu: provided => ({
                    ...provided,
                    zIndex: 4,
                  })
                }}
              />
            </div>
          </div>
        </div>
        {isLoading ? (
          <p>Cargando datos...</p>
        ) : filteredData.length > 0 ? (
          <div className="table-containerT">
            <table>
              <colgroup>
                {selectedColumns.map((column, index) => (
                  <col key={index} style={{ width: `${100 / selectedColumns.length}%` }} />
                ))}
              </colgroup>
              <thead>
                <tr style={{  fontSize: "clamp(12px, 1vw, 12px)" }}>
                  {selectedColumns.map((column, index) => (
                    <th key={index}>{column.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentData.map((item, index) => (
                  <tr key={index} >
                    {selectedColumns.map((column, colIndex) => (
                      <td key={colIndex} style={column.style}>
                        <div>
                          {column.value === 'user' ? (
                            <>
                              <div onClick={() => openPopup(item)} >
                                <PiUserCirclePlusBold className="iconoUserSmall" style={{ color: "#595959" }} />
                              </div>
                              {displayAbbreviation(item[column.value])}

                            </>
                          ) : (
                            item[column.value]
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <BiChevronsLeft style={{ color: '#595959', fontSize: '110%' }} />
              </button>
              <span style={{ textAlign: 'center', fontSize: '60%', color: '#595959' }}>Página {currentPage} de {Math.ceil(filteredData.length / itemsPerPage)}</span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}
              >
                <BiChevronsRight style={{ color: '#595959', fontSize: '110%' }} />
              </button>

            </div>
            <div className="panel-seleccion-columnas">
              {optionalColumns.map((column) => (
                <button className="botones-columnas-dinamicas"
                  key={column.value}
                  onClick={() => toggleColumn(column)}
                  style={{
                    backgroundColor: selectedColumns.some(col => col.value === column.value) ? '#FF00B1' : '#F2F2F2',
                    color: selectedColumns.some(col => col.value === column.value) ? 'white' : '#B9B9B9',
                    border: '1px solid white',
                    borderRadius: '5px',
                    padding: '0.5% 0.5%',
                    maxHeight: "40px",
                    cursor: 'pointer',
                    fontWeight: '650',
                    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                  }}
                >
                  <span style={{ fontSize: '80%' }}>{column.label}</span>
                  <TbColumnInsertLeft style={{ fontSize: '130%' }} />
                </button>
              ))}
              <div style={{
                display: "flex",
                justifyContent: "flex-end",
                height: "100%",
                width: "100%",
                marginTop: "1%"
              }}>
                {/* <ExportToExcel Url={Url2} name="Mejores_Desempeños.xlsx"/> */}
                <div className="navbar-container"
                  style={{ minWidth: '105px', maxWidth: '105px', minHeight: "20px" }} onClick={openPopupexport}
                >
                  <button className="navbar-btn">
                    <IoMdDownload
                      style=
                      {{
                        margin: "0% 5% 0% 5%",
                        width: "13%",
                        height: "auto",
                        color: "white"
                      }} />
                    <div className='text'><h2>Export to excel</h2></div>
                  </button>
                </div>

              </div>
            </div>
            {isPopupOpen && <Popupextra data={popupData} onClose={closePopup} />}
            {isExportPopupOpen && <Popupexport onClose={closePopupexport} />  }


          </div>

        ) : (
          <p style={{ textAlign: "center", marginTop: "20px" }}>
            No hay datos disponibles.
          </p>
        )}
      </div>
      <SimpleModal
        isOpen={isModalOpen}
        message="No hay datos disponibles para este usuario."
        onClose={closeModal}
      />
    </div>
  );
};

export default TabRankings;
