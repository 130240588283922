import TabHistoMsg from "../../Graphs/Client/TabHistory.jsx";
import UrlProvider from "../../../Api/UrlProvider.jsx";

const Board_History= ()=>{

    const Url=`${UrlProvider.defaults.flaskURL}/history`
    /*const Url='https://flaskapimentor.azurewebsites.net/Historical_client/45'*/
    return (
        <div className="container_graph">
            <div className="TabExplorerCliente_User">
                <TabHistoMsg  Url={Url} />
            </div>
        </div>

    );
};
export default Board_History;