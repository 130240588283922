import axios from 'axios';

const UrlProvider = axios.create({
  report:'https://powerbistream.azurewebsites.net',
//Url of local testing API
  flaskURL:'https://test2powerbi-fra9haanh5h2gahs.eastus2-01.azurewebsites.net',
   //url of table explorer data
   new:'http://127.0.0.1:8000',
});

export default UrlProvider;