import React, { useEffect, useState } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar/Sidebar';
import Navbar from '../components/Navbar';
import Board_ExplorerUser from "../components/Boards/Explorer/Board_ExplorerClient";
import Board_History from "../components/Boards/History/Boardhistory";
import Statistics from '../components/Statistics';
import MaintenanceNotice from '../components/Boards/MaintenanceNotice'
import Powerbi from '../components/PowerBi';
import { Routes, Route } from 'react-router-dom';
import { useSidebarContext } from '../components/Sidebar/SidebarContext';
import Popup from '../components/popup';
import { isMobile } from 'react-device-detect';


const Datav = () => {
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    const { isCollapsed } = useSidebarContext();
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        const handleOrientationChange = () => {
            if (isMobile && window.innerHeight > window.innerWidth) {
                setShowPopup(true); 
            } else {
                setShowPopup(false); 
            }
        };

        // Establece el estado inicial según la orientación actual
        handleOrientationChange(); 

        window.addEventListener('resize', handleOrientationChange); 
        return () => window.removeEventListener('resize', handleOrientationChange);
    }, []);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/");
        }
    }, [isAuthenticated, navigate]);

    return (
        <div className={`datav-container ${isCollapsed ? 'sidebar-collapsed' : 'sidebar-expanded'}`}>
            {isAuthenticated && (
                <>
                    {showPopup && <Popup onClose={() => setShowPopup(false)} />}
                    <div className='datav-container'>
                    <Sidebar />
                    <Navbar />
                    <div className='distribution'>
                    <Routes>
                    <Route path="Home" element={<Statistics />} />
                    <Route path="explorer" element={<Board_ExplorerUser />} />
                    <Route path="History" element={<Board_History />} />
                    </Routes>
                    </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Datav;
/* 
<MaintenanceNotice />

*/