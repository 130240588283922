import React, { useState, useEffect } from 'react';
import '../../../styles/Popup.css';
import { IoCloseCircleSharp } from "react-icons/io5";
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";
import { useSidebarContext } from '../../Sidebar/SidebarContext';

const Popupextra = ({ data, onClose }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const { isCollapsed } = useSidebarContext();

  const formatCont = (content) => {
    // eslint-disable-next-line no-useless-escape
    return content
      .replace(/^\d+\.\📝 \*\¡RESPONDE\!\* \\n/, '')
      .replace(/Escribe la respuesta o envía un audio\.\s*\\n/, ''); 
  };
  

  // Calcular índices de paginación
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

  // Calcular el número total de páginas
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    setCurrentPage(1); // Resetear la página actual cuando los datos cambian
  }, [data, itemsPerPage]); // Incluye itemsPerPage si puede cambiar

  if (!data) return null; // Asegurarse de que los datos existan

  return (
    <div className="overlay">
      <div className={`modal-container ${isCollapsed ? 'sidebar-collapsed' : 'sidebar-expanded'}`}>
        <div className="modal-content">
            <div className="headert">
              <IoCloseCircleSharp className="close-icon" onClick={onClose} />
            </div>
            <div className="table-popup" >
              <table  >
                <colgroup >
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "12%" }} />
                  <col style={{ width: "12%" }} />
                  <col style={{ width: "25%" }} />
                </colgroup>
                <thead >
                  <tr style={{fontSize: "clamp(12px, 1vw, 14px)"}}>
                    <th>PREGUNTA</th>
                    <th>RESPUESTA</th>
                    <th>NOTA</th>
                    <th>PUNTAJE</th>
                    <th >RETROALIMENTACIÓN</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((detail, index) => (
                    <tr key={index}>
                      <td>
                        <div className="cell-content"style={{color: "#687079", fontWeight: "400"}}>{formatCont(detail.content)}</div>
                      </td>
                      <td>
                        <div className="cell-content"style={{color: "#687079", fontWeight: "400"}}>{detail.answer}</div>
                      </td>
                      <td>
                        <div className="cell-content" style={{color:"#FF00B1", fontWeight:"bold"}}>{detail.grading}</div>
                      </td>
                      <td>
                        <div className="cell-content"style={{color: "#595959", fontWeight: "700"}} >{detail.q_score}</div>
                      </td>
                      <td>
                        <div className="cell-content"style={{color: "#687079", fontWeight: "400"}}>{detail.feedback}</div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination">
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                  <BiChevronsLeft style={{ color: '#595959', fontSize: '110%' }} />
                </button>
                <span style={{ textAlign: 'center', fontSize: '60%', color: '#595959' }}>
                  Página {currentPage} de {totalPages}
                </span>
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                  <BiChevronsRight style={{ color: '#595959', fontSize: '110%' }} />
                </button>
              </div>
            </div>

            </div>
          </div>
        </div>

  );
};

export default Popupextra;
