import React, { } from 'react';
import '../../../styles/Popup.css';
import { IoCloseCircleSharp } from "react-icons/io5";
import UrlProvider from "../../../Api/UrlProvider.jsx";
import { useSidebarContext } from '../../Sidebar/SidebarContext';
import ExportToExcel from "../../ExportToExcel";


const Url=`${UrlProvider.defaults.flaskURL}/questions`

const Url2=`${UrlProvider.defaults.flaskURL}/capsule_data`

const Popupexport= ({ onClose}) => {
  const { isCollapsed } = useSidebarContext();


  return (
    <div className="overlay">
      <div className={`modal-container ${isCollapsed ? 'sidebar-collapsed' : 'sidebar-expanded'}`}>
        <div className="modal-content">
        <div className="headert">
              <IoCloseCircleSharp className="close-icon" onClick={onClose} />
            </div>

            <div className="button-container">
            <div className="title">
        <h2>Exportación de datos</h2>
      </div>
            <div className="button-column">
           <div className='txtexport'>
            Desempeños por sesiones
           </div>
<div className='button'>
<ExportToExcel Url={Url2} name="Mejores_Desempeños.xlsx"/>

</div>

            </div>
            <div className="button-column">
        
                <div className='txtexport'>
                <p>Desempeños por preguntas </p>
                </div>
<div  className='button'>

<ExportToExcel Url={Url} name="Preguntas_Desempeños.xlsx"/>

</div >
            </div>
          </div>
 

  </div>
          </div>
        </div>

  );
};

export default Popupexport;
