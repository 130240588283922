import React from 'react';

const Simplemodal = ({ isOpen, message, onClose }) => {
  if (!isOpen) return null;

  return (
    <div style={modalStyles.overlay}>
      <div style={modalStyles.modal}>
      <p style={modalStyles.body} dangerouslySetInnerHTML={{ __html: message }}></p>
        <div style={{ height:"auto", width:"100%", display:"flex", alignContent:"center", justifyContent:"center"}}>
            <div className="navbar-container"
            style={{ minWidth: '80px',maxWidth:'80px'}}>
                <button className="navbar-btn" style={{padding:"4%"}}  onClick={onClose}>
                    <div className='text1'><h3>Cerrar</h3></div>
                </button>
            </div>
        </div>

      </div>
    </div>
  );
};

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',

  },
  body: {
    fontSize: 'clamp(16px,1.3vw,18px)',
    color: '#595959',
    fontWeight: '600',
    marginBottom: '20px',
    lineHeight: '1.5',
  },
};

export default Simplemodal;
