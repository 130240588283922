//import React from 'react';
import React, { useEffect } from 'react';
import {useIsAuthenticated } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import SignInButton from '../components/Buttons/SignInButton';
import { ReactComponent as TuIcono } from '../assets/LoginLogo2.svg';

const AuthenticationStatus = () => {
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
     useEffect(() => {
            if (isAuthenticated) {
                navigate("/Datav/Home");
            }
        }, [isAuthenticated, navigate]);
    
    return (
        <div className="AuthenticationStatus-container">
            <div className="container">
             <div className='logo'>
             <TuIcono />
             </div>
            <div className="header">
            <div className="text1">2TOR-IA</div>
            <div className="text2">Business Support</div>
            </div>
            <div className="actions">
                {!isAuthenticated && <SignInButton/>}
            </div>
            
            </div>
            
        </div>
    );
};

export default AuthenticationStatus;
