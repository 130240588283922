import React, { useState, useEffect, useRef } from "react";
import IconoUser from "../../../utils/IconoUser";
import Select from "react-select";
import SearchIcon from "../../../utils/SearchIcon";
import { BiChevronsLeft, BiChevronsRight } from "react-icons/bi";
import { format } from "date-fns";
import ExportToExcel from "../../ExportToExcel";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

const defaultColumns = [
  { value: "user", label: "Usuario", style: { textAlign: "left", color: "#FF00B1", fontWeight: "bold"} },
  { value: "courseName", label: "Curso", style: { textAlign: "left", color: "#687079", fontWeight: "400" } },
  { value: "capsuleName", label: "Cápsula", style: { textAlign: "left", color: "#687079", fontWeight: "400" } },
  { value: "timestamp", label: "Fecha", style: { textAlign: "left", color: "#595959", fontWeight: "700"} },
  { value: "question", label: "Pregunta (user)", style: { textAlign: "left",color: "#687079", fontWeight: "400"  } },
  { value: "answer", label: "Respuesta (bot)", style: { textAlign: "left", color: "#687079", fontWeight: "400" } },
];

const TabHistoMsg = ({ Url }) => {
  // Set default start and end dates
  const currentDate = new Date();
  const weekAgoDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  const defaultStartDate = weekAgoDate.toISOString().split('T')[0];
  const defaultEndDate = currentDate.toISOString().split('T')[0];

  // State variables
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [userFilter, setUserFilter] = useState("");
  const [courseFilter, setCourseFilter] = useState("");
  const [capsuleFilter, setCapsuleFilter] = useState("");
  const [cityFilter, setCityFilter] = useState("");

  const [userOptions, setUserOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [capsuleOptions, setCapsuleOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  const [selectedColumns] = useState(defaultColumns);
  const [originalData, setOriginalData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Paginación
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);

  // Tamaño de la tabla
  const [tableSize, setTableSize] = useState(0);
  const refTableDiv = useRef(0);
  const [sortConfig, setSortConfig] = useState({ key: 'timestamp', direction: 'ascending' });

  // Function to abbreviate text, takes up to three letters after spaces
  const displayAbbreviation = (name) => {
    if (name.includes(" ")) {
      const index = name.indexOf(" ");
      return name.substring(0, index) + ' ' + name.substring(index + 1, index + 2); 
    }
    return name;
  };

  // Fetch data from the endpoint
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(Url);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const rawData = await response.json();

        const tempData = rawData.map((item) => ({
          user: item["user_name"],
          ciudad: item["ciudad"],
          courseName: item["course_name"],
          capsuleName: item["capsule_name"],
          timestamp: format(new Date(item["user_message_timestamp"]), 'dd-MM-yyyy'), 
          question: item["user_message_content"],
          answer: item["bot_response_content"],
        }));
        
        setData(tempData);
        setOriginalData(tempData);
        
        // Set user options based on all data
        const tempUserOptions = [...new Set(tempData.map((item) => item.user))].map(
          (user) => ({ value: user, label: user })
        );
        setUserOptions(tempUserOptions);

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // Ensure loading state is updated
      }
    };

    fetchData();
  }, [Url]);

  // Responsive
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        requestAnimationFrame(() => {
          let containerHeight = entry.contentRect.height;
          let containerWidth = entry.contentRect.width;
          let rowsPerPage = Math.min(Math.floor(containerHeight / 100), 4); // Altura máxima de cada fila es 90px, máximo 4 filas
          if (containerWidth >= containerHeight) {
            rowsPerPage = Math.min(Math.floor(containerHeight / 100), 4); // Máximo 5 filas para pantallas anchas o cuadradas
          } 
          
          else {
            rowsPerPage = Math.min(Math.floor(containerHeight / 100), 4); // Máximo 8 filas para pantallas más altas que anchas
          }
          if (window.innerHeight <= 390) {
            rowsPerPage = 2;
          } else if (window.innerHeight <= 500) {
            rowsPerPage = 2;
          }

          setItemsPerPage(rowsPerPage);
          setTableSize(containerHeight);
        });
      }
    });
    
    resizeObserver.observe(refTableDiv.current);
    
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    const updateFilterOptions = (data) => {
      const tempCourseOptions = [
        ...new Set(data.map((item) => item.courseName)),
      ].map((course) => ({ value: course, label: course }));
      setCourseOptions(tempCourseOptions);
  
      const tempCapsuleOptions = [
        ...new Set(data.map((item) => item.capsuleName)),
      ].map((capsule) => ({ value: capsule, label: capsule }));
      setCapsuleOptions(tempCapsuleOptions);
  
      const tempCityOptions = [
        ...new Set(data.map((item) => item.ciudad)),
      ].map((ciudad) => ({ value: ciudad, label: ciudad }));
      setCityOptions(tempCityOptions);
    };

    const filterDataByAllFilters = () => {
      let filtered = originalData;

      // Filtrar por usuario y actualizar fechas si es necesario
      if (userFilter) {
        const userEntries = filtered.filter((item) => item.user === userFilter);
        if (userEntries.length > 0) {
          // Ordenar por fecha para encontrar la más antigua
          const sortedUserEntries = userEntries.sort((a, b) => new Date(a.timestamp.split('-').reverse().join('-')) - new Date(b.timestamp.split('-').reverse().join('-')));
          setStartDate(sortedUserEntries[0].timestamp.split('-').reverse().join('-'));
          setEndDate(sortedUserEntries[sortedUserEntries.length - 1].timestamp.split('-').reverse().join('-'));
          filtered = userEntries;
        }
      }

      // Filtrar por fecha
      if (startDate) {
        filtered = filtered.filter((item) => {
          const itemDate = new Date(item.timestamp.split('-').reverse().join('-'));
          return itemDate >= new Date(startDate);
        });
      }
      if (endDate) {
        filtered = filtered.filter((item) => {
          const itemDate = new Date(item.timestamp.split('-').reverse().join('-'));
          return itemDate <= new Date(endDate);
        });
      }

      // Aplicar filtro por curso
      if (courseFilter) {
        filtered = filtered.filter((item) => item.courseName === courseFilter);
      }

      // Aplicar filtro por cápsula
      if (capsuleFilter) {
        filtered = filtered.filter((item) => item.capsuleName === capsuleFilter);
      }

      // Aplicar filtro por ciudad
      if (cityFilter) {
        filtered = filtered.filter((item) => item.ciudad === cityFilter);
      }

      // Actualizar las opciones de filtro basadas en los datos filtrados
      updateFilterOptions(filtered);

      // Ordenar los datos filtrados
      const sortedData = sortData(filtered);
      setFilteredData(sortedData);
    };

    filterDataByAllFilters();
  }, [startDate, endDate, userFilter, courseFilter, capsuleFilter, cityFilter, originalData, sortConfig]);

  const handleEndDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setDate(selectedDate.getDate() + 1);
    setEndDate(selectedDate);
  };
  
  // Función para cambiar de página
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= Math.ceil(filteredData.length / itemsPerPage)) {
      setCurrentPage(newPage);
    }
  };

  // Datos para la página actual
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentData = filteredData.slice(startIndex, startIndex + itemsPerPage);
  const sortData = (data) => {
    let sortableData = [...data];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  };
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };
  
  
  
  return (
    <div ref={refTableDiv} className="TabHistoriMensajesU">
      <div className="titulo">
        <h2>Interacciones IA</h2>
      </div>
      
      <div  className="container_graph">
        <div className="filtro">
          <div className='first'>
            <div className='container-input'>
              <txt>Fecha inicio</txt>
              <input
                type="date"
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
                placeholder="Fecha inicial"
              />
            </div>
            <div className='container-input'>
              <txt>Fecha final</txt>
              <input
                type="date"
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
                placeholder="Fecha final"
              />
            </div>
          </div>
          <div className='second'>
            <div className='container-inputc'>
              <txt2>Usuario</txt2>
              <Select
                classNamePrefix="my-select"
                className="select-containerC"
                value={userOptions.find((option) => option.value === userFilter)}
                onChange={(option) => setUserFilter(option ? option.value : "")}
                options={userOptions}
                placeholder={"Buscar usuario"}
                isClearable
                isSearchable
                components={{
                  DropdownIndicator: () => (
                    <div style={{ display: 'flex', alignItems: 'center', marginInline: "0.5vw", width: '1vw', maxWidth:"10px", height: 'auto' }}>
                      <SearchIcon />
                    </div>
                  ),
                }}
                styles={{
                  clearIndicator: provided => ({
                    ...provided,
                    color: 'gray',
                    width: '50%',
                    height: 'auto'
                  }),
                  singleValue: provided => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '65%',
                    width: '100%'
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '60%',
                    width: '100%',
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? '#FFE6F7' : state.isSelected ? '#FFB0E7' : null,
                    color: state.isSelected ? '#FF00B1' : 'grey',
                    cursor: 'pointer',
                    fontSize: '80%'
                  }),
                  input: (provided) => ({
                    ...provided,
                    fontSize: '60%',
                    cursor: 'pointer'
                  }),
                  
                  menu: provided => ({
                    ...provided,
                    zIndex: 4,
                  })
                }}
              />
            </div>
            <div className='container-inputc'>
              <txt2>Curso</txt2>
              <Select
                 classNamePrefix="my-select"
                 className="select-containerC"
                 value={
                   courseOptions.find((option) => option.value === courseFilter) ||
                   null
                 }
                 onChange={(option) => setCourseFilter(option ? option.value : "")}
                 options={courseOptions}
                 placeholder={"Buscar curso"}
                 isClearable
                 isSearchable
                 components={{
                  DropdownIndicator: () => (
                    <div style={{ display: 'flex', alignItems: 'center', marginInline: "0.5vw", width: '1vw', maxWidth:"10px", height: 'auto' }}>
                      <SearchIcon />
                    </div>
                  ),
                }}
                styles={{
                  clearIndicator: provided => ({
                    ...provided,
                    color: 'gray',
                    width: '50%',
                    height: 'auto'
                  }),
                  singleValue: provided => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '65%',
                    width: '100%'
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '60%',
                    width: '100%',
    
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? '#FFE6F7' : state.isSelected ? '#FFB0E7' : null,
                    color: state.isSelected ? '#FF00B1' : 'grey',
                    cursor: 'pointer',
                    fontSize: '80%'
                  }),
                  input: (provided) => ({
                    ...provided,
                    fontSize: '60%',
                    cursor: 'pointer'
    
                  }),
                  menu: provided => ({
                    ...provided,
                    zIndex: 4,
                  })
                }}
              />
            </div>
            <div className='container-inputc'>
              <txt2>Cápsula</txt2>
              <Select
                 classNamePrefix="my-select"
                 className="select-containerC"
                 value={
                   capsuleOptions.find((option) => option.value === capsuleFilter) ||
                   null
                 }
                 onChange={(option) => setCapsuleFilter(option ? option.value : "")}
                 options={capsuleOptions}
                 placeholder={"Buscar cápsula"}
                 isClearable
                 isSearchable
                 components={{
                  DropdownIndicator: () => (
                    <div style={{ display: 'flex', alignItems: 'center', marginInline: "0.5vw", width: '1vw', maxWidth:"10px", height: 'auto' }}>
                      <SearchIcon />
                    </div>
                  ),
                }}
                styles={{
                  clearIndicator: provided => ({
                    ...provided,
                    color: 'gray',
                    width: '50%',
                    height: 'auto'
                  }),
                  singleValue: provided => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '65%',
                    width: '100%'
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '60%',
                    width: '100%',
    
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? '#FFE6F7' : state.isSelected ? '#FFB0E7' : null,
                    color: state.isSelected ? '#FF00B1' : 'grey',
                    cursor: 'pointer',
                    fontSize: '80%'
                  }),
                  input: (provided) => ({
                    ...provided,
                    fontSize: '60%',
                    cursor: 'pointer'
    
                  }),
                  menu: provided => ({
                    ...provided,
                    zIndex: 4,
                  })
                }}
              />
            </div>

            <div className='container-inputc'>
              <txt2>Ciudad</txt2>
              <Select
                 classNamePrefix="my-select"
                 className="select-containerC"
                 value={
                   cityOptions.find((option) => option.value === cityFilter) ||
                   null
                 }
                 onChange={(option) => setCityFilter(option ? option.value : "")}
                 options={cityOptions}
                 placeholder={"Buscar ciudad"}
                 isClearable
                 isSearchable
                 components={{
                  DropdownIndicator: () => (
                    <div style={{ display: 'flex', alignItems: 'center', marginInline: "0.5vw", width: '1vw', maxWidth:"10px", height: 'auto' }}>
                      <SearchIcon />
                    </div>
                  ),
                }}
                styles={{
                  clearIndicator: provided => ({
                    ...provided,
                    color: 'gray',
                    width: '50%',
                    height: 'auto'
                  }),
                  singleValue: provided => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '65%',
                    width: '100%'
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: 'gray',
                    fontSize: '60%',
                    width: '100%',
    
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused ? '#FFE6F7' : state.isSelected ? '#FFB0E7' : null,
                    color: state.isSelected ? '#FF00B1' : 'grey',
                    cursor: 'pointer',
                    fontSize: '80%'
                  }),
                  input: (provided) => ({
                    ...provided,
                    fontSize: '60%',
                    cursor: 'pointer'
    
                  }),
                  menu: provided => ({
                    ...provided,
                    zIndex: 4,
                  })
                }}
              />
            </div>
          </div>
        </div>
        {isLoading ? (
          <p>Cargando datos...</p>
        ) : filteredData.length > 0 ? (
          <div className="table-containerT">
            <table>
            <colgroup>
                <col style={{ width: "15%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "20%" }} />
              </colgroup>
              <thead>
  <tr style={{ fontSize: "clamp(12px, 1vw, 12px)" }}>
    {selectedColumns.map((column, index) => (
      <th
        key={index}
        onClick={() => handleSort(column.value)}
        style={{ cursor: 'pointer' }}
      >
        {column.label}
        {sortConfig.key === column.value && (
          sortConfig.direction === 'ascending' ? (
            <IoMdArrowDropdown style={{ fontSize: '16px', marginLeft: '8px' }} />
          ) : (
            <IoMdArrowDropup style={{ fontSize: '16px', marginLeft: '8px' }} />
          )
        )}
      </th>
    ))}
  </tr>
</thead>


              <tbody>
                {currentData.map((item, index) => (
                  <tr key={index}>
                    {selectedColumns.map((column, colIndex) => (
                      <td key={colIndex} style={column.style}>
                        <div className="content">
                          {column.value === "user" ? (
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
                              <IconoUser className="iconoUserSmall" />
                              {displayAbbreviation(item[column.value])}
                              <br />
                              <div  className="city" >{item["ciudad"]}</div>
                            </div>
                            
                          ) : (
                            item[column.value]
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <BiChevronsLeft style={{ color: '#595959', fontSize: '110%' }}/>
              </button>
              <span style={{ textAlign: 'center', fontSize:'60%', color: '#595959'}}>Página {currentPage} de {Math.ceil(filteredData.length / itemsPerPage)}</span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}
              >
                 <BiChevronsRight style={{ color: '#595959', fontSize: '110%' }}/>
              </button>
              
            </div>
            <div style={{
              width:"100%",
              maxWidth: "950px",
              marginBottom:"1%",
              height:"40px"
              }}>
            <ExportToExcel Url={Url} name="Interacciones_IA.xlsx"/>
            </div>
          </div>
          
        ) : (
          <p style={{ textAlign: "center", marginTop: "20px" }}>
          No hay datos disponibles.
        </p>
        )}
     
      </div>
    </div>
  );
};

export default TabHistoMsg;